import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import LogoS from '../../assets/images/logo-dfw.png'
import LogoSubtitle from '../../assets/images/logo-sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faClose, faEnvelope, faHome, faUser} from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'
function Sidebar () {   
   const [showNav, setShowNav] = useState(false);
   return (
    <div className='nav-bar'>
         <Link className='logo' to='/'>
            <img src={LogoS} alt="logo" />
            <img src={LogoSubtitle} alt="web-design" />
         </Link>
         <nav className={showNav ? 'mobile-show' : ''}>
         <NavLink 
            exact="true" activeclassname="active" to="/"
            onClick={() => setShowNav(false)}
         >
         <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
         </NavLink>

            <NavLink 
               onClick={() => setShowNav(false)}
               exact="true" 
               activeclassname="active"
               className="about-link"
               to="/about"
            >
               <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink 
               onClick={() => setShowNav(false)}
               exact="true" 
               activeclassname="active"
               className="contact-link"
               to="/contact"
             >
               <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
            </NavLink>

            <FontAwesomeIcon 
               onClick={() => setShowNav(false)}
               icon={faClose}
               color="#ffd700"
               size='3x'
               className='close-icon'
            />
         </nav>
         <ul>
            <li>
               <a 
               target="_blank" 
               rel="noreferrer"
               href='https://github.com/ryanliu1030'
               >
                  <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
               </a> 

            </li>
            <li>
               <a 
               target="_blank" 
               rel="noreferrer"
               href='www.google.com'
               >
                  <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
               </a> 

            </li>

            <li>
               <a 
               target="_blank" 
               rel="noreferrer"
               href='https://discordapp.com/users/186718384455548929'
               >
                  <FontAwesomeIcon icon={faDiscord} color="#4d4d4e" />
               </a> 

            </li>
         </ul>
         <FontAwesomeIcon 
            onClick={() => setShowNav(true)}
            icon={faBars}
            color="#ffd700"
            size='3x'
            className='hamburger-icon'
         />
      </div>
   )
}

 export default Sidebar